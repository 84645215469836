<template>
  <div class="class-detail-wrapper">
    <div
      class="class-detail-left-section"
      @click="displayResults"
      @mouseover="classFocused"
      @mouseleave="classNotFocused"
    >
      <p class="primary trialClass">
        <strong>{{ trialClass.trialClass }}</strong>
      </p>
      <p>{{ trialClass.level }}</p>
      <p class="handler">
        <font-awesome-icon :icon="['fa', 'address-card']"></font-awesome-icon>
        {{ trialClass.judge }}
      </p>
    </div>
    <div class="class-detail-right-section">
      <div class="registered-dogs">
        <div>
          <p @click="openDialogue" class="handler-dropdown">
            <font-awesome-icon :icon="['fa', 'user']"></font-awesome-icon>
            {{ getHandler }}
          </p>
        </div>
      </div>
      <p
        @click="displayResults"
        :class="{ classFocused: isClassFocused }"
        class="view-results-link"
      >
        <font-awesome-icon :icon="['fa', resultsIcon]"></font-awesome-icon>
        {{ resultsButtonText }}
      </p>
    </div>
    <div class="remove-button">
      <font-awesome-icon
        :icon="['fa', 'minus-circle']"
        @click="removeClass"
      ></font-awesome-icon>
    </div>
  </div>
  <el-dialog
    title="Change Handler"
    :modelValue="dialogVisible"
    :before-close="closeDialogue"
    width="40%"
  >
    <base-spinner v-if="isUpdating"></base-spinner>
    <el-select v-else-if="!isUpdating" v-model="updatedHandler">
      <el-option
        v-for="handler in dogHandlers"
        :key="handler.itemId"
        :label="handler.handlerName"
        :value="handler.handlerName"
      >
      </el-option>
    </el-select>
    <h3 v-else>Something went wrong</h3>
    <span class="buttons">
      <base-button color="secondary" @click="updateHandler">Save</base-button>
      <base-button color="tertiary" @click="closeDialogue">Cancel</base-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: { trialClass: Object, trialType: String },
  data() {
    return {
      dogId: this.$store.getters["dog/getDog"]._id,
      dialogVisible: false,
      updatedHandler: undefined,
      isUpdating: false,
      isClassFocused: false,
      dogHandler: undefined,
      resultsEntered: undefined,
    };
  },
  computed: {
    getHandler() {
      const dogHandler = this.trialClass.registrations.find(
        (registration) => registration.dogId === this.dogId
      );
      return dogHandler.handler;
    },
    dogHandlers() {
      return this.$store.getters["dog/getDog"].handlers;
    },
    getDog() {
      return this.$store.getters["dog/getDog"];
    },
    getActiveClasses() {
      return this.$store.getters["dog/getActiveClasses"];
    },
    resultsButtonText() {
      return this.resultsEntered ? "View Results" : "Enter Results";
    },
    resultsIcon() {
      return this.resultsEntered ? "clipboard-list" : "clipboard";
    },
  },
  methods: {
    async displayResults() {
      // trialType gets passed down in case there needs to be
      // a seperate action for Upcoming Trials and Past Trials

      // set trialClass in store
      await this.$store.dispatch("dog/setDogActiveClass", this.trialClass);
      // push route to dogClassResults
      this.$router.push({
        name: "dogClassResults",
        params: {
          dogId: this.dogId,
          classId: this.trialClass._id,
        },
      });
    },
    async updateHandler() {
      this.isUpdating = true;
      await this.$store.dispatch("dog/updateClassRegistration", {
        dogId: this.dogId,
        classId: this.trialClass._id,
        update: { handler: this.updatedHandler },
      });
      this.closeDialogue();
      this.updatedHandler = undefined;
      this.isUpdating = false;
    },
    openDialogue() {
      this.dialogVisible = true;
    },
    closeDialogue() {
      this.dialogVisible = false;
    },
    removeClass() {
      this.$confirm(
        `Are you sure you want to remove ${this.getDog.callName} from ${this.trialClass.trialClass} and delete all stats for this class?`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            await this.$store.dispatch("dog/removeDogFromTrialClass", {
              trialId: this.trialClass.trialId,
              trialClass: this.trialClass,
              dogId: this.dogId,
            });

            this.$message({
              showClose: true,
              message: `${this.getDog.callName} removed from ${this.trialClass.trialClass}`,
              type: "error",
              duration: 5000,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error TCD1100 removing dog from trial class",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Remove Cancelled",
          })
        );
    },
    classFocused() {
      this.isClassFocused = true;
    },
    classNotFocused() {
      this.isClassFocused = false;
    },
  },
  created() {
    const dogRegistration = this.trialClass.registrations.find(
      (registration) => registration.dogId === this.dogId
    );

    this.dogHandler = dogRegistration.handler;
    this.resultsEntered = dogRegistration.results ? true : false;
  },
};
</script>

<style lang="scss" scoped>
// Class detail styles are handled in the _variable.scss file

.class-detail-wrapper {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .class-detail-right-section {
    padding-top: 7px;
  }

  .registered-dogs {
    margin: 5px 0;
  }

  .view-results-link {
    display: inline-block;
    color: $primary;
  }

  .view-results-link:hover,
  .classFocused {
    color: $secondary;
  }

  .handler-dropdown {
    display: inline-block;
  }

  .handler-dropdown:hover {
    color: $secondary;
  }
}

.buttons {
  button {
    margin: 0 0 0 10px;
  }
}

.remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;

  svg {
    opacity: 0.5;
  }

  svg:hover {
    color: $secondary;
    opacity: 1;
  }
}
</style>
