<template>
  <div class="wrapper" v-if="hasActiveTrial">
    <div class="dog-top-actions">
      <el-tooltip effect="dark" content="Register for Classes" placement="left">
        <font-awesome-icon
          :icon="['fa', 'plus-circle']"
          @Click="handleAddClasses"
        ></font-awesome-icon>
      </el-tooltip>
    </div>

    <TrialHeader :trial="getTrial" />
    <base-spinner v-if="!hasActiveClasses"></base-spinner>
    <section class="class-columns" v-else-if="hasActiveClasses">
      <div
        v-for="trialClassesDate in getActiveClasses"
        :key="trialClassesDate.classDate"
        class="trial-class-column"
      >
        <div class="column-date">
          <p>
            {{ formatTrialClassDate(trialClassesDate.classDate) }}
          </p>
        </div>
        <base-spinner v-if="isLoadingIndividualClasses"></base-spinner>
        <div
          v-else-if="
            !isLoadingIndividualClasses &&
            trialClassesDate.trialClasses.length > 0
          "
        >
          <DogClassDetail
            v-for="trialClass in trialClassesDate.trialClasses"
            :key="trialClass._id"
            :trialClass="trialClass"
            :trialType="trialType"
          />
        </div>
        <div v-else>
          <p>No class registrations for this date</p>
          <el-tooltip
            effect="dark"
            content="Register for Classes"
            placement="left"
          >
            <font-awesome-icon
              :icon="['fa', 'plus-circle']"
              class="add-classes-icon"
              @Click="handleAddClasses"
            ></font-awesome-icon>
          </el-tooltip>
        </div>
      </div>
    </section>
    <el-drawer
      custom-class="add-classes-drawer"
      title="Add Classes Drawer"
      :modelValue="addClassesOpen"
      :with-header="false"
      :before-close="closeAddClasses"
    >
      <AddClasses :refreshDrawerKey="refreshDrawerKey" />
    </el-drawer>
  </div>
</template>

<script>
import TrialHeader from "@/components/trial/TrialHeader.vue";
import DogClassDetail from "./DogClassDetail.vue";
import AddClasses from "./AddClasses.vue";

export default {
  props: { trialType: String, trialId: String },
  components: { TrialHeader, DogClassDetail, AddClasses },
  data() {
    return {
      token: this.$store.getters["user/getUserTokens"].idToken,
      userId: this.$store.getters["user/getUserId"],
      isLoadingTrial: false,
      isLoadingIndividualClasses: false,
      addClassesOpen: false,
      refreshDrawerKey: 0,
    };
  },
  computed: {
    getDog() {
      return this.$store.getters["dog/getDog"];
    },
    getTrial() {
      return this.$store.getters["dog/getDogActiveTrial"]
        ? this.$store.getters["dog/getDogActiveTrial"]
        : this.getTrialFromDB();
    },
    getActiveClasses() {
      return this.$store.getters["dog/getDogActiveClasses"]
        ? this.$store.getters["dog/getDogActiveClasses"]
        : this.getActiveClassesFromDB();
    },
    hasActiveTrial() {
      return this.$store.getters["dog/getDogActiveTrial"] ? true : false;
    },
    hasActiveClasses() {
      return this.$store.getters["dog/getDogActiveClasses"] ? true : false;
    },
  },
  methods: {
    async getTrialFromDB() {
      this.isLoadingTrial = true;

      if (this.token && this.userId) {
        try {
          await this.$store.dispatch("dog/loadDogActiveTrial", this.trialId);
        } catch (error) {
          console.error(error);
          this.$message({
            showClose: true,
            message: "Error getting trial information",
            type: "error",
          });
          this.activeDogClasses = [];
        }
      } else {
        console.log("no userid and/or token to get trial information");
      }

      this.isLoadingTrial = false;
    },
    formatTrialClassDate(dateToFormat) {
      return this.getTrial.formatMonthAndDay(dateToFormat);
    },
    handleAddClasses() {
      this.addClassesOpen = true;
      this.refreshDrawerKey++;
    },
    closeAddClasses() {
      this.addClassesOpen = false;
    },
    async getActiveClassesFromDB() {
      this.isLoadingIndividualClasses = true;
      if (this.token && this.userId) {
        try {
          await this.$store.dispatch("dog/loadDogActiveClasses");
        } catch (error) {
          console.error(error);
          this.$message({
            showClose: true,
            message: "Error getting dog classes",
            type: "error",
          });
        }
      } else {
        console.log("no userid and/or token to get trial information");
      }
      this.isLoadingIndividualClasses = false;
    },
  },
  created() {
    this.getActiveClassesFromDB();
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

.class-columns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 30px;
}

.column-date {
  margin-bottom: 15px;
  border-bottom: 1px solid $tertiary-15;

  p {
    margin-bottom: 5px;
  }
}

.trial-class-column {
  width: 360px;
  min-height: 100px;
  margin-right: 50px;
}

.add-classes-icon {
  color: $tertiary-75;

  :hover {
    color: $secondary;
    cursor: pointer;
  }
}
</style>
