<template>
  <base-spinner v-if="isLoading"></base-spinner>
  <div v-else-if="!isLoading && getAvailableClasses">
    <div v-for="classDate in getAvailableClasses" :key="classDate">
      <p class="class-date">{{ formatTrialClassDate(classDate.classDate) }}</p>
      <div v-for="trialClass in classDate.trialClasses" :key="trialClass._id">
        <div v-if="!isRegistered(trialClass)">
          <TrialClassDetail
            :trialClass="trialClass"
            :registeredDogs="[]"
            trialOrDogView="dog"
            :upcomingOrPast="getUpcomingOrPast"
            :trial="getTrial"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrialClassDetail from "../trial/TrialClassDetail.vue";

export default {
  // the refreshDrawerKey is only used to force an update of this component
  props: { refreshDrawerKey: Number },
  components: { TrialClassDetail },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    getDogId() {
      return this.$store.getters["dog/getDog"]._id;
    },
    getAvailableClasses() {
      return this.$store.getters["dog/getDogActiveTrialClasses"];
    },
    getUpcomingOrPast() {
      const activeTrial = this.$store.getters["dog/getDogActiveTrial"];
      return new Date(activeTrial.startDate) >= new Date()
        ? "upcoming"
        : "past";
    },
    getTrial() {
      return this.$store.getters["dog/getDogActiveTrial"];
    },
  },
  watch: {
    refreshDrawerKey() {
      this.loadDogActiveTrialClasses();
    },
  },
  methods: {
    loadDogActiveTrialClasses() {
      this.isLoading = true;
      const trialId = this.$store.getters["dog/getDogActiveTrial"]._id;
      this.$store.dispatch("dog/setDogActiveTrialClasses", trialId);
      this.isLoading = false;
    },
    isRegistered(trialClass) {
      return trialClass.registrations.some(
        (registration) =>
          registration.dogId === this.getDogId && !registration.isDeleted
      );
    },

    formatTrialClassDate(dateToFormat) {
      return this.$store.getters["dog/getDogActiveTrial"].formatMonthAndDay(
        dateToFormat
      );
    },
  },
  created() {
    this.loadDogActiveTrialClasses();
  },
};
</script>

<style lang="scss" scoped>
.class-date {
  padding-bottom: 5px;
  padding-top: 20px;
  font-weight: bold;
  border-bottom: 1px solid $tertiary-25;
}
</style>
